/**
 * BASE PATH for APIS
 */
// export const BASE_URL = "http://20.124.126.130:3000/api/v1";
//export const BASE_URL = "http://localhost:5000/api/v1";
//export const BASE_URL = 'https://devapi.ambaram.ai/api/v1';
export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

// export const BASE_URL = "http://service.ambaram.ai/api/v1";
// export const BASE_URL = "https://test-service.ambaram.ai/api/v1";

// export const BASE_URL = (process.env.NODE_ENV == 'development' )? "http://localhost:3000/api/v1" : "https://test-service.ambaram.ai/api/v1";
export const STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
//export const STORAGE_BUCKET =
//'https://ambaram.blob.core.windows.net/test-container/';
// export const BASE_URL = "http://localhost:3000/api/v1";

// export const SOCKET_ENDPOINT = 'http://service.ambaram.ai'
// export const SOCKET_ENDPOINT = 'https://devapi.ambaram.ai';
export const SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT;
// export const SOCKET_ENDPOINT = 'http://localhost:3000'

/** 
export const SOCKET_ENDPOINT = (process.env.NODE_ENV == 'development' )? 'http://localhost:3000' : "https://test-service.ambaram.ai/api/v1";

/*
 * Authbv
 */
export const AUTH_ENDPOINT = `${BASE_URL}/auth`;
/**
 * NonAuth APIS
 */
export const SIGN_UP = `${AUTH_ENDPOINT}/register`;
export const MY_PROFILE = `${AUTH_ENDPOINT}/getProfile`;
export const UPDATE_PROFILE = `${AUTH_ENDPOINT}/updateProfile`;

//fileUploadle
export const FILE_UPLOAD = `${AUTH_ENDPOINT}/fileUpload`;

// Email setting
export const EMAIL_SETTING = `${BASE_URL}/emailSetting`;

/**
 * NonAuth APIS
 */
export const CATEGORY_ENDPOINT = `${BASE_URL}/category`;

/*** SubCategory */
export const SUB_CATEGORY = `${BASE_URL}/subCategory`;

/*** Tag */
export const TAG = `${BASE_URL}/tag`;

/** * fabric */
export const FABRIC = `${BASE_URL}/fabric`;

/**
 * season
 */
export const SEASON = `${BASE_URL}/season`;

// General
export const GENERAL = `${BASE_URL}/general`;
// GeneralSetting
export const GENERAL_SETTING = `${BASE_URL}/generalSetting`;

// notification Setting
export const NOTIFICATION_SETTING = `${BASE_URL}/notificationSetting`;

// Currency
export const CURRENCY = `${GENERAL}/currency`;

//Language
export const LANGUAGE = `${GENERAL}/language`;

//Team
export const TEAM = `${BASE_URL}/team`;

//Role
export const ROLE = `${BASE_URL}/role`;

//User
export const USER = `${BASE_URL}/users`;

export const UPDATE_PERMISSION = `${BASE_URL}/users/updatePermissions`;

//all users
export const ALLUSERS = `${BASE_URL}/users/allUsers`;

//Product
export const PRODUCT = `${BASE_URL}/product`;

//Create Product link
export const CREATE_LINK = `${BASE_URL}/shareProductLink`;

export const SHARED_PRODUCT = `${BASE_URL}/product/shareProduct`;

//Colour
export const COLOUR = `${BASE_URL}/colour`;

//Colour
export const COLOUR_FAVORITE = `${BASE_URL}/colour/favorite`;

//Colour
export const COLOUR_BY_TEAM = `${BASE_URL}/colour/colorByTeam`;

// role Permission
export const ROLE_PERMISSION = `${BASE_URL}/rolePermission`;

// global search
export const GLOBAL_SEARCH = `${BASE_URL}/globalSearch`;

//productComments
export const PRODUCT_COMMENT = `${BASE_URL}/product/productComments`;
export const PRODUCT_COMMENT_NO_AUTH = `${BASE_URL}/product/productCommentsNoAuth`;

//productImgComments
export const PRODUCT_IMG_COMMENT = `${BASE_URL}/product/productImgComments`;
export const PRODUCT_IMG_COMMENT_NON_AUTH = `${BASE_URL}/product/productImgCommentsNonAuth`;

//getProductImgComments
export const GET_PRODUCT_IMG_COMMENT = `${BASE_URL}/product/getProductImgComments`;
export const GET_PRODUCT_IMG_COMMENT_NON_AUTH = `${BASE_URL}/product/getProductImgCommentsNonAuth`;

//productComments
export const PRODUCT_FILTER = `${BASE_URL}/product/productFilter`;

//productByTeam
export const GET_PRODUCT_BY_TEAM = `${BASE_URL}/product/productByTeam`;

//shareWithEmail
export const SHARE_WITH_EMAIL = `${BASE_URL}/product/shareWithEmail`;

//shareWithEmail
export const ACTIVE_PRODUCT = `${BASE_URL}/dashboard/activeProducts`;

//trendingFabrics
export const TRENDING_FABRICS = `${BASE_URL}/dashboard/trendingFabrics`;

//totalUploads
export const TOTAL_UPLOADS = `${BASE_URL}/dashboard/totalUploads`;

//addReminder
export const REMINDER = `${BASE_URL}/reminder`;

//aidesignmate
export const AIDESIGNMATE = `${BASE_URL}/aidesignmate`;

//REMINDER_BY_TEAM
export const REMINDER_BY_TEAM = `${BASE_URL}/reminder/reminderByTeam`;

//GET PLANS
export const GET_PLANS = `${BASE_URL}/payment`;

//addSubscription
export const ADD_SUBSCRIPTION = `${BASE_URL}/payment/addSubscription`;

//Cancel Subscription
export const CANCLE_SUBSCRIPTION = `${BASE_URL}/payment/cancleSubscription`;

//Get pricing Data
export const GET_PRICING_DATA = `${BASE_URL}/price/6448d5f436689734389c4742`;

//update set Question in userModel
export const UPDATE_QUESTIONS = `${AUTH_ENDPOINT}/updateQuestions`;
